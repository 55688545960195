import * as React from 'react';
import styles from '../styles/wizards/AddForeignEditorsWizard.scss';
import { __, validateForm } from '../core/utils';
import AddTeamIcon from '../assets/images/add-team-60x60.svg';
import { setNotification } from '../redux/actions/general/notification';
import {
  createSpecificationSetForeignEditors,
  readSpecificationSet,
} from '../redux/actions/table/specification_sets';
import { connect } from 'react-redux';
import AddForeignEditorsForm from '../forms/add_foreign_editors_wizard/AddForeignEditorsForm';

const AddForeignEditorsWizard = (props) => {
  const _handleSubmit = (formData) => {
    const specification_set_id = props.table.flyout.id;
    const { edit } = formData;

    formData = {
      ..._.omit(formData, ['edit']),
      foreign_editors: _.map(formData.foreign_editors, 'id'),
    };

    return validateForm(
      createSpecificationSetForeignEditors(specification_set_id, formData).then(
        () => {
          readSpecificationSet(specification_set_id);

          props.onClose();

          setNotification(__('wizard.add-foreign-editor.message.success'));
        }
      )
    );
  };

  const { foreign_editors } = props.table.flyout;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>
            {__('wizard.specification-sets.title.add-foreign-editor')}
          </span>
          <span>
            {__('wizard.specification-sets.subtitle.add-foreign-editor')}
          </span>
        </div>
        <div className={styles.right}>
          <AddTeamIcon />
        </div>
      </div>
      <div className={styles.body}>
        <AddForeignEditorsForm
          onSubmit={_handleSubmit}
          initialValues={{
            foreign_editors,
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['specification_sets'],
  };
};

export default connect(mapStateToProps, null)(AddForeignEditorsWizard);
