import * as React from 'react';
import { Component } from 'react';
import { Redirect, Switch } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';
import DynamicRoute from '../helpers/DynamicRoute';
import Login from '../views/auth/Login';
import Register from '../views/auth/Register';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PersonalDetails from '../views/profile/PersonalDetails';
import ForgotPassword from '../views/auth/ForgotPassword';
import ResetPassword from '../views/auth/ResetPassword';
import LocalizationContext from '../helpers/Localization';
import CompanyDetails from '../views/company/CompanyDetails';
import CompanyMarket from '../views/company/CompanyMarket';
import CompanyDisciplines from '../views/company/CompanyDisciplines';
import CompanyOffices from '../views/company/CompanyOffices';
import { removePlusAction } from '../redux/reducers/general';
import AllCompanies from '../views/companies_and_users/AllCompanies';
import PartnerCompanies from '../views/companies_and_users/PartnerCompanies';
import ApproveAccount from '../views/company/ApproveAccount';
import PartnerMembers from '../views/companies_and_users/PartnerMembers';
import MyCompanyMembers from '../views/companies_and_users/MyCompanyMembers';
import AllProjects from '../views/projects/AllProjects';
import Stages from '../views/stages/Stages';
import ProjectGeneral from '../views/projects/ProjectGeneral';
import StageDisciplines from '../views/stage_disciplines/StageDisciplines';
import Teams from '../views/teams/Teams';
import TeamMemberConfirmation from '../views/teams/TeamMemberConfirmation';
import Notifications from '../views/profile/Notifications';
import { getUser } from '../redux/actions/auth';
import queryString from 'query-string';
import { initSocketIO, listenNotificationsUpdated } from './sockets';
import PlanUploads from '../views/plans/PlanUploads';
import WorkingSetPlans from '../views/plans/WorkingSetPlans';
import PlanVersionPreview from '../views/plans/PlanVersionPreview';
import MyDeliveries from '../views/plans/MyDeliveries';
import PublicDeliveries from '../views/plans/PublicDeliveries';
import PlanApprovals from '../views/plans/PlanApprovals';
import CurrentSetPlans from '../views/plans/CurrentSetPlans';
import PlanRevisionPreview from '../views/plans/PlanRevisionPreview';
import DeliveryPlans from '../views/plans/DeliveryPlans';
import AllTasks from '../views/tasks/AllTasks';
import { clearNotifications } from '../redux/actions/general/notification';
import { removePlusActions } from '../redux/actions/general/plus';
import { clearActiveModules } from '../redux/actions/general/active_modules';
import { hideDropdown } from '../redux/actions/general/dropdown';
import PartnerMemberApproval from '../views/company/PartnerMemberApproval';
import { listLocalization } from '../redux/actions/localization';
import * as Sentry from '@sentry/browser';
import { hideSidebar } from '../redux/actions/general/sidebar';
import Documents from '../views/documents/Documents';
import AllMeetings from '../views/meetings/AllMeetings';
import MeetingItems from '../views/meetings/MeetingItems';
import { generalCheck } from '../redux/actions/general/mainenance_mode';
import MaintenanceMode from '../containers/MaintenanceMode';
import ReactGA from 'react-ga';
import Products from '../views/products/Products';
import SuperadminProducts from '../views/superadmin/Products';
import Security from '../views/profile/Security';
import UniqueURL from '../views/general/UniqueURL';
import Sites from '../views/space_allocation/Sites';
import Buildings from '../views/space_allocation/Buildings';
import Storeys from '../views/space_allocation/Storeys';
import Zones from '../views/space_allocation/Zones';
import ExpansionJoints from '../views/space_allocation/ExpansionJoints';
import Entrances from '../views/space_allocation/Entrances';
import Rooms from '../views/space_allocation/Rooms';
import { screenIs, setPageTitle } from './utils';
import RoomGroups from '../views/space_allocation/RoomGroups';
import RoomTypes from '../views/space_allocation/RoomTypes';
import OCRTemplates from '../views/plans/OCRTemplates';
import OCRTemplateEditor from '../views/plans/OCRTemplateEditor';
import StartPage from '../views/general/StartPage';
import NotificationsSettings from '../views/profile/NotificationsSettings';
import Companies from '../views/superadmin/Companies';
import Users from '../views/superadmin/Users';
import CurrentSetSpecifications from '../views/specifications/CurrentSetSpecifications';
import MySpecificationDeliveries from '../views/specifications/MySpecificationDeliveries';
import PublicSpecificationDeliveries from '../views/specifications/PublicSpecificationDeliveries';
import SpecificationApprovals from '../views/specifications/SpecificationApprovals';
import DeliverySpecifications from '../views/specifications/DeliverySpecifications';
import Materials from '../views/superadmin/Materials';
import Languages from '../views/superadmin/Languages';
import RawMaterials from '../views/superadmin/RawMaterials';
import Invalid from '../views/general/Invalid';
import AddressBookCompanies from '../views/companies_and_users/AddressBookCompanies';
import AddressBookContacts from '../views/companies_and_users/AddressBookContacts';
import Translations from '../views/superadmin/Translations';
import Notes from '../views/superadmin/Notes';
import NoteCategories from '../views/superadmin/NoteCategories';
import Colors from '../views/superadmin/Colors';
import PaperSizes from '../views/superadmin/PaperSizes';
import Countries from '../views/superadmin/Countries';
import Units from '../views/superadmin/Units';
import ProductAttributeTemplateGroups from '../views/superadmin/ProductAttributeTemplateGroups';
import Regions from '../views/superadmin/Regions';
import UnitGroups from '../views/superadmin/UnitGroups';
import BuildingTypes from '../views/superadmin/BuildingTypes';
import FunctionalSpaces from '../views/superadmin/FunctionalSpaces';
import ProductAttributeTemplateFields from '../views/superadmin/ProductAttributeTemplateFields';
import Disciplines from '../views/superadmin/Disciplines';
import RawColors from '../views/superadmin/RawColors';
import ProductCategories from '../views/superadmin/ProductCategories';
import ProductGroups from '../views/superadmin/ProductGroups';
import ProductSubgroups from '../views/superadmin/ProductSubgroups';
import SpecificationGroups from '../views/superadmin/SpecificationGroups';
import SpecificationSubgroups from '../views/superadmin/SpecificationSubgroups';
import Brands from '../views/company/Brands';
import PublicBrands from '../views/products/PublicBrands';
import WorkingSetBOQ from '../views/boq/WorkingSetBOQ';
import WorkingSetSpecifications from '../views/specifications/WorkingSetSpecifications';
import BOQTemplateGroups from '../views/superadmin/BOQTemplateGroups';
import BOQTemplateSubgroups from '../views/superadmin/BOQTemplateSubgroups';
import BOQSupplyTypeGroups from '../views/superadmin/BOQSupplyTypeGroups';
import BOQSupplyTypes from '../views/superadmin/BOQSupplyTypes';
import Filters from '../views/superadmin/Filters';
import MainLayout from '../layouts/MainLayout';
import AuthLayout from '../layouts/AuthLayout';
import EmptyLayout from '../layouts/EmptyLayout';
import ImportSpreadsheet from '../views/import_spreadsheet/ImportSpreadsheet';
import Tenders from '../views/tender/Tenders';
import TenderBOQ from '../views/tender/TenderBOQ';
import BOQTemplateCalculations from '../views/superadmin/BOQTemplateCalculations';
import ImportBOQSpreadsheet from '../views/boq/ImportBOQSpreadsheet';
import SpecificationSets from '../views/specifications/SpecificationSets';
import SpecificationSetItems from '../views/specifications/SpecificationSetItems';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    localization: state.localization,
    maintenance_mode: state.general.maintenance_mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        listLocalization,
        removePlusAction,
      },
      dispatch
    ),
  };
};
@connect(mapStateToProps, mapDispatchToProps)
class Router extends Component {
  componentDidMount() {
    getUser();
    listLocalization().then(() => {
      setPageTitle(null);
    });
    generalCheck();

    const user = this.props.auth;

    if (this.props.auth.id) {
      if (process.env.SENTRY_DSN) {
        Sentry.configureScope((scope) => {
          scope.setUser(_.pick(user, ['id', 'email', 'role']));
        });
      }

      if (process.env.GOOGLE_ANALYTICS_ID) {
        ReactGA.set({
          userId: user.id,
        });
      }
    }
  }

  maintenanceChecker = null;

  /**
   * After getUser() listener
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    //clear active project/stage on back or forward
    window.onpopstate = () => {
      clearActiveModules();
    };

    const user = this.props.auth;

    if (process.env.SENTRY_DSN) {
      if (!prevProps.auth.id && this.props.auth.id) {
        Sentry.configureScope((scope) => {
          scope.setUser(_.pick(user, ['id', 'email', 'role']));
        });
      }

      if (prevProps.auth.id && !this.props.auth.id) {
        Sentry.configureScope((scope) => {
          scope.clear();
        });
      }
    }

    if (process.env.GOOGLE_ANALYTICS_ID) {
      if (!prevProps.auth.id && this.props.auth.id) {
        ReactGA.set({
          userId: user.id,
        });
      }

      if (prevProps.auth.id && !this.props.auth.id) {
        ReactGA.set({
          userId: null,
        });
      }
    }

    screenIs('<=', 1024) && hideSidebar();

    if (_.get(prevProps, 'auth.id') != _.get(this.props, 'auth.id')) {
      //if just logged in
      if (_.get(this.props, 'auth.id')) {
        //TODO - some of these are called twice
        clearNotifications();
        removePlusActions();
        clearActiveModules();
        hideDropdown();

        // Init and listen to sockets
        initSocketIO();
        listenNotificationsUpdated();
      }
    }

    if (!this.maintenanceChecker) {
      this.maintenanceChecker = setInterval(() => {
        generalCheck();
      }, 1000 * 60 * 5); // fire check every 5 minutes
    }
  }

  render() {
    const return_url =
      this.props.history.location.pathname +
      this.props.history.location.search +
      this.props.history.location.hash;
    const { maintenance_mode, auth } = this.props;
    const company = !_.isEmpty(auth.company);

    return (
      <LocalizationContext.Provider value={this.props.localization}>
        {maintenance_mode && <MaintenanceMode />}
        {!maintenance_mode &&
          !_.isUndefined(this.props.auth.loggedIn) &&
          !_.isEmpty(this.props.localization) && (
            <ConnectedRouter history={this.props.history}>
              {this.props.auth.loggedIn ? (
                <Switch>
                  {company && (
                    <DynamicRoute
                      layout={MainLayout}
                      exact
                      path='/(projects)?'
                      component={AllProjects}
                    />
                  )}
                  {!company && (
                    <DynamicRoute
                      layout={MainLayout}
                      exact
                      path='/'
                      component={StartPage}
                    />
                  )}
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/profile/details'
                    component={PersonalDetails}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/profile/security'
                    component={Security}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/profile/notifications'
                    component={Notifications}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/profile/notifications/settings'
                    component={NotificationsSettings}
                  />
                  {_.includes(
                    ['administrator', 'owner'],
                    this.props.auth.role
                  ) && (
                    <DynamicRoute
                      layout={MainLayout}
                      exact
                      path='/company/details'
                      component={CompanyDetails}
                    />
                  )}
                  {/*{_.includes(["administrator", "owner"], this.props.auth.role) && <DynamicRoute layout={MainLayout} exact path="/company/subscription" component={CompanySubscription} />}*/}
                  {_.includes(
                    ['administrator', 'owner'],
                    this.props.auth.role
                  ) && (
                    <DynamicRoute
                      layout={MainLayout}
                      exact
                      path='/company/market'
                      component={CompanyMarket}
                    />
                  )}
                  {_.includes(
                    ['administrator', 'owner'],
                    this.props.auth.role
                  ) && (
                    <DynamicRoute
                      layout={MainLayout}
                      exact
                      path='/company/disciplines'
                      component={CompanyDisciplines}
                    />
                  )}
                  {_.includes(
                    ['administrator', 'owner'],
                    this.props.auth.role
                  ) && (
                    <DynamicRoute
                      layout={MainLayout}
                      exact
                      path='/company/offices'
                      component={CompanyOffices}
                    />
                  )}
                  {_.includes(
                    ['administrator', 'owner'],
                    this.props.auth.role
                  ) && (
                    <DynamicRoute
                      layout={MainLayout}
                      exact
                      path='/company/brands'
                      component={Brands}
                    />
                  )}
                  {_.includes(
                    ['administrator', 'owner'],
                    this.props.auth.role
                  ) && (
                    <DynamicRoute
                      layout={AuthLayout}
                      exact
                      path='/company/approve_member/:token'
                      component={ApproveAccount}
                    />
                  )}
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/companies'
                    component={AllCompanies}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/companies/partner'
                    component={PartnerCompanies}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/companies/partner_members'
                    component={PartnerMembers}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/companies/my/members'
                    component={MyCompanyMembers}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/address_book/companies'
                    component={AddressBookCompanies}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/address_book/contacts'
                    component={AddressBookContacts}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/projects/:project/stages'
                    component={Stages}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/import/:stage'
                    component={ImportSpreadsheet}
                  />

                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/boq/import'
                    component={ImportBOQSpreadsheet}
                  />

                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/approvers'
                    component={StageDisciplines}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/teams'
                    component={Teams}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/projects/:project/general'
                    component={ProjectGeneral}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/join_team'
                    component={TeamMemberConfirmation}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/plans/uploads'
                    component={PlanUploads}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/plans/working'
                    component={WorkingSetPlans}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/plans/current'
                    component={CurrentSetPlans}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/plans/versions/:plan_version'
                    component={PlanVersionPreview}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/deliveries/my'
                    component={MyDeliveries}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/deliveries'
                    component={PublicDeliveries}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/deliveries/:type(my|public)/:delivery'
                    component={DeliveryPlans}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/approvals/:status(pending|approved|rejected)?'
                    component={PlanApprovals}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/plans/revisions/:plan_revision'
                    component={PlanRevisionPreview}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/tasks'
                    component={AllTasks}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/partner_member_approval'
                    component={PartnerMemberApproval}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/products'
                    component={Products}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    exact
                    path='/products/public_brands'
                    component={PublicBrands}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/specifications/working'
                    component={WorkingSetSpecifications}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/specifications/sets'
                    component={SpecificationSets}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/specifications/sets/:specification_set'
                    component={SpecificationSetItems}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/specifications/current'
                    component={CurrentSetSpecifications}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/specification_deliveries/my'
                    component={MySpecificationDeliveries}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/specification_deliveries'
                    component={PublicSpecificationDeliveries}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/specification_approvals/:status(pending|approved|rejected)?'
                    component={SpecificationApprovals}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/specification_deliveries/:type(my|public)/:specification_delivery'
                    component={DeliverySpecifications}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/boq/working'
                    component={WorkingSetBOQ}
                  />
                  {/* <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/documents'
                    component={Documents}
                  /> */}
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/meetings'
                    component={AllMeetings}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/meetings/:meeting'
                    component={MeetingItems}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/tenders'
                    component={Tenders}
                  />

                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/tenders/:tender/boq'
                    component={TenderBOQ}
                  />

                  <DynamicRoute
                    layout={EmptyLayout}
                    remountable
                    exact
                    path='/url/:token'
                    component={UniqueURL}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/space_allocation/sites'
                    component={Sites}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/space_allocation/buildings'
                    component={Buildings}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/space_allocation/storeys'
                    component={Storeys}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/space_allocation/zones'
                    component={Zones}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/space_allocation/expansion_joints'
                    component={ExpansionJoints}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/space_allocation/entrances'
                    component={Entrances}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/space_allocation/rooms'
                    component={Rooms}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/space_allocation/room_groups'
                    component={RoomGroups}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/space_allocation/room_types'
                    component={RoomTypes}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/stages/:stage/ocr_templates'
                    component={OCRTemplates}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/ocr_templates/:ocr_template'
                    component={OCRTemplateEditor}
                  />

                  <DynamicRoute
                    layout={AuthLayout}
                    remountable
                    exact
                    path='/invalid'
                    component={Invalid}
                  />
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_companies && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/companies'
                        component={Companies}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_users && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/users'
                        component={Users}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_materials && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/materials'
                        component={Materials}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_raw_materials && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/raw_materials'
                        component={RawMaterials}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_languages && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/languages'
                        component={Languages}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_translations && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/translations'
                        component={Translations}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_note_categories && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/note_categories'
                        component={NoteCategories}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_notes && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/notes'
                        component={Notes}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_colors && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/colors'
                        component={Colors}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_paper_sizes && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/paper_sizes'
                        component={PaperSizes}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin
                      .list_product_attribute_template_groups && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/product_attribute_template_groups'
                        component={ProductAttributeTemplateGroups}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin
                      .list_product_attribute_template_fields && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/product_attribute_template_fields'
                        component={ProductAttributeTemplateFields}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_countries && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/countries'
                        component={Countries}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_units && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/units'
                        component={Units}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_regions && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/regions'
                        component={Regions}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_unit_groups && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/unit_groups'
                        component={UnitGroups}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_building_types && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/building_types'
                        component={BuildingTypes}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_functional_spaces && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/functional_spaces'
                        component={FunctionalSpaces}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_disciplines && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/disciplines'
                        component={Disciplines}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_raw_colors && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/raw_colors'
                        component={RawColors}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_products && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/products'
                        component={SuperadminProducts}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_product_categories && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/product_categories'
                        component={ProductCategories}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_product_groups && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/product_groups'
                        component={ProductGroups}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_product_subgroups && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/product_subgroups'
                        component={ProductSubgroups}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_specification_groups && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/specification_groups'
                        component={SpecificationGroups}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_specification_subgroups && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/specification_subgroups'
                        component={SpecificationSubgroups}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_boq_template_groups && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/boq_template_groups'
                        component={BOQTemplateGroups}
                      />
                    )}

                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin.list_boq_template_subgroups && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/boq_template_subgroups'
                        component={BOQTemplateSubgroups}
                      />
                    )}
                  {this.props.auth.superadmin &&
                    this.props.auth.superadmin
                      .list_boq_template_calculations && (
                      <DynamicRoute
                        layout={MainLayout}
                        remountable
                        exact
                        path='/superadmin/boq_template_calculations'
                        component={BOQTemplateCalculations}
                      />
                    )}
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/superadmin/boq_supply_types'
                    component={BOQSupplyTypes}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/superadmin/boq_supply_type_groups'
                    component={BOQSupplyTypeGroups}
                  />
                  <DynamicRoute
                    layout={MainLayout}
                    remountable
                    exact
                    path='/superadmin/filters'
                    component={Filters}
                  />

                  <Redirect to='/' />
                </Switch>
              ) : (
                <Switch>
                  <DynamicRoute
                    layout={AuthLayout}
                    exact
                    path='/login'
                    component={Login}
                  />
                  <DynamicRoute
                    layout={AuthLayout}
                    exact
                    path='/register/:token?'
                    component={Register}
                  />
                  <DynamicRoute
                    layout={AuthLayout}
                    exact
                    path='/forgot_password'
                    component={ForgotPassword}
                  />
                  <DynamicRoute
                    layout={AuthLayout}
                    exact
                    path='/reset_password'
                    component={ResetPassword}
                  />
                  <DynamicRoute
                    layout={AuthLayout}
                    exact
                    path='/partner_member_approval'
                    component={PartnerMemberApproval}
                  />
                  <DynamicRoute
                    layout={AuthLayout}
                    remountable
                    exact
                    path='/url/:token'
                    component={UniqueURL}
                  />

                  <Redirect
                    to={
                      return_url.length > 1
                        ? '/login?' + queryString.stringify({ return_url })
                        : '/login'
                    }
                  />
                </Switch>
              )}
            </ConnectedRouter>
          )}
      </LocalizationContext.Provider>
    );
  }
}

export default Router;
